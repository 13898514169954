<template>

    <my-scene/>

</template>

<script>
import {defineComponent} from 'vue';
import MyScene from '@/components/MyScene';

export default defineComponent({
    components: {
        MyScene,
    },
});
</script>
